import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateInput } from 'rsuite';
import 'rsuite/DateInput/styles/index.css';

import '../style/post.css';
import { uploadPhoto } from '../functions/firebase';
import { getFormattedDate } from '../functions/util_fn';

const AUTOSAVE_DELAY_MS = 0; // 3 seconds

function AttributeInput(props) {

    const [value, setValue] = useState(() => {
        if (props.type === 'checkbox') {
            return props.initialValue || false;
        }
        return props.initialValue || '';
    });
    const [editing, setEditing] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const inputRef = useRef(null);
    const fileInputRef = useRef(null);
    const debounceTimer = useRef(null);

    useEffect(() => {
        if (props.initialValue !== undefined) {
            setValue(props.initialValue);
        }
    }, [props.initialValue]);

    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const handleAddPhotoClick = () => {
        fileInputRef.current.click();
    };

    // Consolidated file handling function
    const handleFileChange = async (event) => {
        const files = event.target.files;
        if (!files || files.length === 0) return;

        // Add photo limit check
        if (props.type === 'multiple-images' && files.length > 8) {
            alert('You can only upload up to 8 photos per post');
            return;
        }

        setIsUploading(true);
        props.showLoadingMessage('Uploading Photo...');

        try {
            if (props.type === 'multiple-images') {
                // Handle multiple images
                await props.onMultiplePhotos(files);
            } else {
                // Handle single image
                const fileURL = await uploadPhoto(files[0], props.user.uid, 'photos');
                props.addPostInfo("post-photo", fileURL);
                setValue(fileURL); // Update local state to show the image was uploaded
            }
        } catch (error) {
            console.error('Error uploading photo:', error);
            // Optionally show error message to user
        } finally {
            props.hideLoadingMessage();
            setIsUploading(false);
        }
    };

    const handleCheckboxChange = () => {
        const newValue = !value;
        setValue(newValue);
        props.addPostInfo(props.title, newValue);
    };

    const showInput = () => {
        setEditing(true);
    }

    const handleTextChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }
        
        debounceTimer.current = setTimeout(() => {
            props.addPostInfo(props.title, newValue);
            // setEditing(false);
        }, AUTOSAVE_DELAY_MS);
    };

    const handleDateChange = (dateObject) => {
        const formattedDate = getFormattedDate(dateObject);
        setValue(formattedDate);
        
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }
        
        debounceTimer.current = setTimeout(() => {
            props.addPostInfo(props.title, formattedDate);
            // setEditing(false);
        }, AUTOSAVE_DELAY_MS);
    };

    useEffect(() => {
        return () => {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
        };
    }, []);

    const handleDeletePhoto = () => {
        props.addPostInfo("post-photo", null);
        setValue(null); // Add this state to track if photo is uploaded
    };

    

    const renderEditingContent = () => {
        switch (props.type) {
            case 'text':
                return (
                    <>
                        <Col xs="11">
                            <input 
                                type="text" 
                                value={value} 
                                onChange={handleTextChange}
                                ref={inputRef} 
                            />
                        </Col>
                    </>
                );
            case 'date':
                return (
                    <>
                        <Col xs="11">
                            {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? (
                                <input
                                    type="date"
                                    value={value ? new Date(value).toISOString().split('T')[0] : ''}
                                    onChange={(e) => {
                                        const date = new Date(e.target.value);
                                        handleDateChange(date);
                                    }}
                                    ref={inputRef}
                                />
                            ) : (
                                <DateInput 
                                    format="MM/dd/yyyy" 
                                    size="lg" 
                                    ref={inputRef} 
                                    onChange={handleDateChange}
                                />
                            )}
                        </Col>
                    </>
                );
            case 'select':
                return (
                    <>
                        <Col xs="11">
                            <select 
                                value={value ? value.projectId : ''} 
                                onChange={(e) => {
                                    const selectedProject = props.options.find(p => p.projectId === e.target.value);
                                    setValue(selectedProject);
                                    props.addPostInfo(props.title, selectedProject);
                                    setEditing(false);
                                }}
                                onBlur={() => setEditing(false)}
                                ref={inputRef}
                            >
                                <option value="">Select a project</option>
                                {props.options?.map((option) => (
                                    <option key={option.projectId} value={option.projectId}>
                                        {option[props.optionLabelKey]}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </>
                );
            default:
                return null;
        }
    };

    const renderStaticContent = () => {
        switch (props.type) {
            case 'text':
                return (
                    <>
                        <Col xs="10" onClick={showInput}>
                            <span>{ value ? value : props.title }</span>
                        </Col>
                        <Col xs="1" onClick={showInput}>
                            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                        </Col>
                    </>
                )
            case 'date':
                return (
                    <>
                        <Col xs="10" onClick={showInput}>
                            <span>{ value ? value : props.title }</span>
                        </Col>
                        <Col xs="1" onClick={showInput}>
                            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                        </Col>
                    </>
                )
            case 'multiple-images':
                return (
                    <>
                        <Col xs="10" onClick={handleAddPhotoClick}>
                            <span>
                                {isUploading ? 'Uploading...' : 
                                 props.initialValue?.length > 0 ? 'Change Photos' : props.title}
                            </span>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={handleFileChange}
                                multiple
                                disabled={isUploading}
                            />
                        </Col>
                        <Col xs="1">
                            {props.initialValue?.length > 0 && !isUploading ? (
                                <FontAwesomeIcon 
                                    icon="fa-solid fa-trash" 
                                    onClick={handleDeletePhoto}
                                />
                            ) : (
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            )}
                        </Col>
                    </>
                )
            case 'checkbox':
                return (
                    <>
                        <Col xs="10" onClick={handleCheckboxChange}>
                            <span>{props.title}</span>
                        </Col>
                        <Col xs="1">
                            <input
                                type="checkbox"
                                checked={value}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                    </>
                );
            case 'select':
                return (
                    <>
                        <Col xs="10" onClick={showInput}>
                            <span>
                                {value ? value[props.optionLabelKey] : props.title}
                            </span>
                        </Col>
                        <Col xs="1" onClick={showInput}>
                            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                        </Col>
                    </>
                );
            default:
                return null;
        }
    }
    

  return (
    <div className="post">
        <Container fluid>
            <Row>
                <Col xs="1">
                    <FontAwesomeIcon icon={`fa-solid ${props.icon}`} />
                </Col>
                { editing ?
                    renderEditingContent() : renderStaticContent() 
                }
            </Row>
        </Container>
    </div>
  );
}

export default AttributeInput;
