import mixpanel from 'mixpanel-browser';

var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);

export const getTimeFromNow = (createdAtTimeStamp) => {
    const createdAtDate = new Date(createdAtTimeStamp.seconds * 1000);
    const oneMonthAgo = dayjs().subtract(1, 'month');

    if (dayjs(createdAtDate).isSameOrBefore(oneMonthAgo)) {
        return dayjs(createdAtDate).format('MMMM DD, YYYY');
    } else {
        return dayjs(createdAtDate).fromNow();
    }
}

export const getFormattedDate = (dateObject) => {
    return dayjs(dateObject).format('MM/DD/YYYY');
}

export const getHobbyInfo = (hobbyID, hobbies) => {
    var hobby = hobbies.find(hobby => hobby.id === hobbyID);
    return hobby;
} 

export const hardRefreshPage = () => {
    window.location.href=window.location.href
}

export const getMilestonesOnly = (posts) => {
    return posts.filter(post => post.milestone);
}

// Given a URL, copy it to the clipboard
// If URL is null, use the current URL
export const handleShareLink = async (url) => {
    if(url == null){
        url = window.location.href;
    }

    if (navigator.share) {
        try {
            await navigator.share({
                url
            });
            console.log('Profile shared successfully');
            return false;
        } catch (error) {
            console.error('Error sharing profile:', error);
            return false;
        }
    } else {
        try {
            await navigator.clipboard.writeText(url);
            return true;
            // Optionally, you could provide feedback to the user here, like showing a toast notification
        } catch (error) {
            alert('Failed to copy URL:', error);
            return false;
            // Optionally, handle the error, such as showing an error message to the user
        }
    }
};

export const getUIDFromURL = () => {
    const pathname = window.location.pathname;
    const pathParts = pathname.split('/');
    const userIdFromUrl = pathParts.length > 2 ? pathParts[2] : null;
    return userIdFromUrl;
}

export const allowWriteAccess = (userObj) => {
    if (userObj.accessToken) {
        const pathname = window.location.pathname;
        const pathParts = pathname.split('/');
        
        // Check if either UID matches or username matches
        if (userObj.uid === getUIDFromURL() || 
            (userObj.username && pathParts.length > 2 && pathParts[2] === userObj.username)) {
            return true;
        }
    }
    return false;
}

export const mpTrack = (event, properties) => {
    // Skip tracking if running on localhost
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        console.log('Skipping mixpanel tracking on localhost:', event, properties);
        return;
    }
    mixpanel.track(event, properties);
}

export const flattenObjectForMP = (obj) => {
    let flattened = {};
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            const flatObject = flattenObjectForMP(obj[key]);
            for (let flatKey in flatObject) {
                flattened[key + '.' + flatKey] = flatObject[flatKey];
            }
        } else {
            flattened[key] = obj[key];
        }
    }
    return flattened;
}
