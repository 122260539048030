import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Row, Col, Container, Button, Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryClient } from '@tanstack/react-query';  // Add this import

import Post from '../components/post';
import AttributeInput from '../components/attributeInput';
import '../style/post.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useProfile } from '../assets/data/providerContext';
import { createNewPost, createNewProject, getProjects, updatePost, uploadPhoto } from '../functions/firebase';
import { mpTrack, flattenObjectForMP } from '../functions/util_fn';

import LoadingNotificationBar from '../components/loadingNotificationBar';
import ProjectForm from '../components/forms/ProjectForm';
import HobbySelector from '../components/HobbySelector';
import NewPostHeader from '../components/NewPostHeader';
import MultiPostForm from '../components/MultiPostForm';

function NewPost(props) {

    const [page, setPage] = useState('one');
    const [postInfo, setPostInfo] = useState({});
    const [postContent, setPostContent] = useState(''); // State for textarea content
    const [selectedHobbyIndex, setSelectedHobbyIndex] = useState(null); // State for selected hobby index
    const [showLoadingBar, setShowLoadingBar] = useState({display: false, message:''});
    const [isLoading, setIsLoading] = useState(false);
    const [postType, setPostType] = useState('post'); // 'post' or 'project'
    const [projectState, setProjectState] = useState('active'); // 'active' or 'completed'
    const [availableProjects, setAvailableProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [showHobbyError, setShowHobbyError] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { postId } = useParams();
    const isEditMode = props.mode === 'edit';

    const profileContext = useProfile();
    const profileInfo = profileContext == null ? null : profileContext.profileInfo;

    const queryClient = useQueryClient();  // Add this hook

    useEffect(() => {
        mpTrack('Initated New Post');
    },[])

    useEffect(() => {
        const fetchProjects = async () => {
            if (props.user) {
                const projects = await getProjects(props.user.uid);
                setAvailableProjects(projects);
            }
        };
        fetchProjects();
    }, [props.user]);

    useEffect(() => {
        if (postInfo.hobby && availableProjects.length > 0) {
            const relevantProjects = availableProjects
                .filter(project => project.hobby.id === postInfo.hobby.id)
                .map(project => ({
                    projectId: project.id,
                    title: project.title
                }));
            setFilteredProjects(relevantProjects);
        } else {
            setFilteredProjects([]);
        }
    }, [postInfo.hobby, availableProjects]);

    // Initialize state with existing post data if in edit mode
    useEffect(() => {
        if (isEditMode && location.state?.postData) {
            const postData = location.state.postData;
            setPostInfo({
                'Add Title': postData.title,
                'Add Location': postData.location,
                'images': postData.images || [postData.imageUrl],
                'Private': postData.isPrivate,
                'Link to Project': postData.linked_project,
                hobby: postData.hobby
            });
            setPostContent(postData.content);
            // Find and set hobby index
            const hobbyIndex = profileInfo?.hobbies.findIndex(h => h.id === postData.hobby.id);
            setSelectedHobbyIndex(hobbyIndex);
        }
    }, [isEditMode, location.state]);

    // Function for input componenets to add post info to state
    const addPostInfo = (key, value) => {
        setPostInfo((prevState) => {
            const updatedPostInfo = { ...prevState, [key]: value };
            console.log("New Post Info: ", updatedPostInfo);
            return updatedPostInfo;
        });
    }

    // Add this new function to handle multiple photo uploads
    const handleMultiplePhotos = async (files) => {
        if (!isLoading && !showLoadingBar.display) {
            setIsLoading(true);
            showLoadingMessage('Uploading Photos...');

            try {
                const uploadPromises = Array.from(files).map(file => 
                    uploadPhoto(file, props.user.uid, 'photos')
                );

                const uploadedUrls = await Promise.all(uploadPromises);
                addPostInfo('images', uploadedUrls);
                hideLoadingMessage();
            } catch (error) {
                console.error("Error uploading multiple photos:", error);
                hideLoadingMessage();
            }

            setIsLoading(false);
        }
    };

    const changeFormPage = (page) => {
        if (!isLoading && !showLoadingBar.display) {
            if (page === 'two' && !postInfo.hobby) {
                console.log("Hobby not selected");
                setShowToast(true);
                return;
            }
            setShowHobbyError(false);
            setPage(page);
        }
    }

    const handlePostContentChange = (e) => {
        setPostContent(e.target.value); // Update state with textarea content
    };

    const handleHobbySelect = (hobby, index) => {
        setSelectedHobbyIndex(index);
        addPostInfo('hobby', hobby);
    };

    const handlePostSubmit = async () => {
        if (!isLoading && !showLoadingBar.display) {
            setIsLoading(true);
            showLoadingMessage(isEditMode ? 'Updating Post...' : 'Uploading Post...');

            var compositePostInfo = {
                content: postContent,
                hobby: postInfo.hobby,
                title: postInfo['Add Title'],
                images: postInfo['images'] || (postInfo['post-photo'] ? [postInfo['post-photo']] : []),
                location: postInfo['Add Location'],
                milestone: postInfo['Milestone'],
                privatePost: postInfo['Private'],
                linked_project: postInfo['Link to Project']
            };

            if (postInfo['Change Date']) {
                compositePostInfo.postDate = new Date(postInfo['Change Date']);
            }

            try {
                let success;
                if (isEditMode) {
                    success = await updatePost(props.user.uid, postId, compositePostInfo);
                    if (success) {
                        // Invalidate posts query after successful update
                        queryClient.invalidateQueries(['posts', props.user.uid]);
                        mpTrack("Post Updated", flattenObjectForMP(compositePostInfo));
                    }
                } else {
                    success = await createNewPost(props.user.uid, compositePostInfo);
                    if (success) {
                        // Invalidate posts query after successful creation
                        queryClient.invalidateQueries(['posts', props.user.uid]);
                        mpTrack("Post Created", flattenObjectForMP(compositePostInfo));
                    }
                }

                if (success) {
                    await profileContext.refreshProfileInfo();
                    setShowLoadingBar(false);
                    goBack();
                }
            } catch (error) {
                console.error("Error saving post:", error);
                hideLoadingMessage();
            }

            setIsLoading(false);
        }
    };

    const handleProjectSubmit = async () => {
        if (!isLoading && !showLoadingBar.display) {
            setIsLoading(true);
            showLoadingMessage('Creating Project...');

            var projectInfo = {
                title: postInfo['Add Title'],
                hobby: postInfo.hobby,
                description: postContent,
                status: projectState,
                imageUrl: postInfo['post-photo']
            };

            try {
                var successfullyCreated = await createNewProject(props.user.uid, projectInfo);
                if (successfullyCreated) {
                    // Invalidate projects query after successful creation

                    queryClient.invalidateQueries(['projects', props.user.uid]);

                    mpTrack("Project Created", flattenObjectForMP(projectInfo));
                    await profileContext.refreshProfileInfo();
                    setShowLoadingBar(false);
                    navigate(`/u/${props.user.uid}/project/${successfullyCreated}`, {
                        state: {
                            project: {
                                id: successfullyCreated,
                                title: projectInfo.title,
                                description: projectInfo.description,
                                status: projectState,
                                imageUrl: projectInfo.imageUrl,
                                // Add any other relevant project info here
                            }
                        }
                    });
                } else {
                    console.error("Failed to create project");
                    hideLoadingMessage();
                }
            } catch (error) {
                console.error("Error creating project:", error);
                hideLoadingMessage();
            }

            setIsLoading(false);
        }
    };

    const goBack = () => {
        navigate(-1);
    }

    const showLoadingMessage = (message) => {
        setShowLoadingBar({ display: true, message: message });
    }

    const hideLoadingMessage = () => {
        setShowLoadingBar({ display: false, message: '' });
    }

    const togglePostType = () => {
        if (!isLoading && !showLoadingBar.display) {
            setPostType(postType === 'post' ? 'project' : 'post');
            // Reset form when switching types
            setPage('one');
            setPostInfo({});
            setPostContent('');
            setSelectedHobbyIndex(null);
        }
    };

    return (
        <div className={isLoading ? 'loading-overlay' : ''}>
            <>
                <NewPostHeader 
                    page={page}
                    postType={postType}
                    selectedHobbyIndex={selectedHobbyIndex}
                    onBack={goBack}
                    onChangePage={changeFormPage}
                    onTogglePostType={togglePostType}
                    onSubmit={postType === 'project' ? handleProjectSubmit : handlePostSubmit}
                    isEditMode={isEditMode}
                />

                {postType === 'project' ? (
                    <ProjectForm 
                        user={props.user}
                        onSubmit={handleProjectSubmit}
                        profileInfo={profileInfo}
                        postInfo={postInfo}
                        addPostInfo={addPostInfo}
                        postContent={postContent}
                        handlePostContentChange={handlePostContentChange}
                        selectedHobbyIndex={selectedHobbyIndex}
                        handleHobbySelect={handleHobbySelect}
                        projectState={projectState}
                        setProjectState={setProjectState}
                    />
                ) : postType === 'multipost' ? (
                    <MultiPostForm 
                        user={props.user}
                        profileInfo={profileInfo}
                        onSubmit={goBack}
                        showLoadingMessage={showLoadingMessage}
                        hideLoadingMessage={hideLoadingMessage}
                    />
                ) : (
                    <>
                        {page === 'one' ? (
                            <Container fluid className='content-below-nav'>
                                <p style={{
                                    marginTop: '54px',
                                    color: showHobbyError ? 'red' : 'inherit'
                                }}>Select Hobby</p>
                                <HobbySelector 
                                    hobbies={profileInfo?.hobbies}
                                    selectedIndex={selectedHobbyIndex}
                                    onSelect={handleHobbySelect}
                                />

                                <AttributeInput 
                                    addPostInfo={addPostInfo} 
                                    title="Add Photo" 
                                    icon="fa-image" 
                                    type="multiple-images" 
                                    user={props.user} 
                                    showLoadingMessage={showLoadingMessage} 
                                    hideLoadingMessage={hideLoadingMessage}
                                    onMultiplePhotos={handleMultiplePhotos}
                                    initialValue={postInfo['images']}
                                />
                                <AttributeInput 
                                    addPostInfo={addPostInfo} 
                                    title="Add Title" 
                                    icon="fa-font" 
                                    type="text"
                                    initialValue={postInfo['Add Title']}
                                />

                                <p style={{marginTop: '16px'}}>Post</p>
                                <textarea 
                                    placeholder="Write your thoughts here..."
                                    className='outline'
                                    value={postContent}
                                    onChange={handlePostContentChange}
                                />
                                {/* <Button 
                                    variant="outline-primary" 
                                    className="mt-3 w-100"
                                    onClick={() => setPostType('multipost')}
                                >
                                    Create Multiple Posts
                                </Button> */}
                            </Container>
                        ) : (
                            <Container fluid className={`content-below-nav ${ showLoadingBar.display ? "remove-top-margin" : "" }`}>
                                <Row>
                                    <Col xs="12">
                                        <Post
                                            content={postContent}
                                            title={postInfo['Add Title'] ? postInfo['Add Title'] : null}
                                            category={postInfo.hobby ? postInfo.hobby.hobbyName : ''}
                                            color="1"
                                            location={postInfo['Add Location'] ? postInfo['Add Location'] : null}
                                            imageUrl={postInfo['post-photo'] ? postInfo['post-photo'] : null}
                                            images={postInfo['images']}
                                            hideMenu={true}
                                            isPrivate={postInfo['Private']}
                                        />
                                        <AttributeInput 
                                            addPostInfo={addPostInfo} 
                                            title="Add Location" 
                                            icon="fa-location-dot" 
                                            type="text"
                                            initialValue={postInfo['Add Location']}
                                        />
                                        {filteredProjects.length > 0 && (
                                            <AttributeInput 
                                                addPostInfo={addPostInfo} 
                                                title="Link to Project" 
                                                icon="fa-link" 
                                                type="select"
                                                options={filteredProjects}
                                                optionLabelKey="title"
                                                initialValue={postInfo['linked_project']}
                                            />
                                        )}
                                        <AttributeInput 
                                            addPostInfo={addPostInfo} 
                                            title="Change Date" 
                                            icon="fa-calendar-week" 
                                            type="date"
                                            initialValue={postInfo['Change Date']}
                                        />
                                        <AttributeInput 
                                            addPostInfo={addPostInfo} 
                                            title="Milestone" 
                                            icon="fa-star" 
                                            type="checkbox"
                                            initialValue={postInfo['Milestone']}
                                        />
                                        <AttributeInput 
                                            addPostInfo={addPostInfo} 
                                            title="Private" 
                                            icon="fa-eye" 
                                            type="checkbox"
                                            initialValue={postInfo['Private']}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        )}
                    </>
                )}
            </>
            <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 100,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000,
                    fontSize: '18px'
                }}
            >
                <Toast.Body>
                    <FontAwesomeIcon icon="fa-triangle-exclamation" style={{ color: '#2bcbba', marginRight: '10px' }} />
                    Select a hobby for the post
                </Toast.Body>
            </Toast>
        </div>
    );
}

export default NewPost;
