import React, { useState } from 'react';
import { Nav, Navbar, Row, Col, Container, Button, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../style/post.css';
import { getTimeFromNow, handleShareLink  } from '../functions/util_fn';
import PopupOptionsMenu from './popupOptionsMenu';
import { deletePost } from '../functions/firebase';


const CONTENT_TRUNCATE_LENGTH = 178;

function Post({ postID, user, title, content, createdAt, daysAgo, location, category, color, imageUrl, images, hideMenu, clickable, hobbyID, isPrivate, linked_project }) {
    const navigate = useNavigate();
    var URLlocation = useLocation();
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [showFullContent, setShowFullContent] = useState(false);

    const goToHobbyPage = () => {
        const targetUrl = `/u/${user.username}/hobby/${hobbyID}`;
        
        // Check if the current URL is different from the target URL
        if (URLlocation.pathname !== targetUrl) {
            navigate(targetUrl, {
                state: {
                    "origin_url": URLlocation.pathname
                }
            });
        } else {
            console.log('Already on the target URL');
        }
    }

    const goToProjectPage = () => {
        if (linked_project?.projectId) {
            navigate(`/u/${user.username}/project/${linked_project.projectId}`, {
                state: {
                    "origin_url": URLlocation.pathname
                }
            });
        }
    }

    const toggleOptionsModal = () => {
        setShowOptionsModal(!showOptionsModal);
    }

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    }

    const shortenedContent = content.length > CONTENT_TRUNCATE_LENGTH ? 
        content.slice(0, CONTENT_TRUNCATE_LENGTH) + '...' : content;

    const handleSharePostLink = async () => {
        const postUrl = `${window.location.origin}/u/${user.username}/post/${postID}`;
        await handleShareLink(postUrl);
        toast("Copied link", {
            autoClose: 3000,
            hideProgressBar: false,
        });
    }

    const handleDeletePost = async () => {
        await deletePost(user.uid, postID);
        // Refresh the page on the same URL
        window.location.reload();
    }

    const handleEditPost = () => {
        navigate(`/u/${user.username}/edit-post/${postID}`, {
            state: {
                postData: {
                    title: title,
                    content: content,
                    location: location,
                    imageUrl: imageUrl,
                    isPrivate: isPrivate,
                    linked_project: linked_project,
                    hobby: {
                        id: hobbyID,
                        hobbyName: category
                    }
                }
            }
        });
    };

    const postMenuOptions = [
        {
            icon: "fa-solid fa-share-from-square",
            text: "Share Post Link",
            onClick: () => {
                handleSharePostLink();
            },
            requiresWriteAccess: false
        },
        
        {
            icon: "fa-solid fa-pen-to-square",
            text: "Edit Post",
            onClick: handleEditPost,
            requiresWriteAccess: true
        },
        {
            icon: "fa-solid fa-trash-can",
            color: "red",
            text: "Delete Post",
            onClick: () => {
                handleDeletePost();
            },
            requiresWriteAccess: true
        },
    ]

    const handleCarouselTouch = (e) => {
        e.stopPropagation();  // Prevents event from bubbling up to page swipe handler
    };

    return (
    <div className="post">
        { showOptionsModal ?
            <PopupOptionsMenu
                user={user}
                toggleShowPopupOptions={toggleOptionsModal}
                    buttons={postMenuOptions}
            /> : <></>
        }
        <Container fluid>
            <Row>
                <Col xs="12">
                    <div className='post-container'>
                        { hideMenu ? <></> : <div className='menu-icon' onClick={toggleOptionsModal}><FontAwesomeIcon icon="fa-solid fa-ellipsis" /></div>}
                        {category && color && (
                            <div className="post-category">
                                <div className={`post-category-circle color-${color}`}></div>
                                <div
                                    onClick={clickable ? (() => { goToHobbyPage() }) : (() => {})}
                                >{category}</div>
                            </div>
                        )}
                        
                        {(images?.length > 0 || imageUrl) && (
                            <div 
                                onTouchStart={handleCarouselTouch}
                                onTouchMove={handleCarouselTouch}
                                onTouchEnd={handleCarouselTouch}
                            >
                                <Carousel 
                                    interval={null} 
                                    controls={false} 
                                    className="post-carousel"
                                    indicators={images?.length > 1 || false}
                                >
                                    {images ? 
                                        // Show multiple images if they exist
                                        images.map((img, index) => (
                                            <Carousel.Item key={index}>
                                                <div className="post-image">
                                                    <img src={img} alt={`Post ${index + 1}`} />
                                                </div>
                                            </Carousel.Item>
                                        ))
                                        : 
                                        // Fall back to single imageUrl
                                        <Carousel.Item>
                                            <div className="post-image">
                                                <img src={imageUrl} alt="Post" />
                                            </div>
                                        </Carousel.Item>
                                    }
                                </Carousel>
                            </div>
                        )}
                    <div className="post-title">{title}</div>
                    <p className="post-content">
                        {showFullContent ? content : shortenedContent}
                        {content.length > CONTENT_TRUNCATE_LENGTH && !showFullContent && (
                            <span className="primary-text-color" onClick={toggleContent}> Show More</span>
                        )}
                    </p>
                    { linked_project ? 
                        <div className="post-project-tag" onClick={goToProjectPage}>
                            {linked_project.title}
                        </div> 
                        : <></>
                        }
                    <div className="post-footer">
                        { isPrivate ? <span><FontAwesomeIcon icon="fa-solid fa-eye-slash" /> Private</span>  : <></>}
                        <span className="post-days">{ createdAt ? getTimeFromNow(createdAt) : "" }</span>
                        <span className="post-location">{ location ? <><FontAwesomeIcon icon="fa-solid fa-location-dot" /> {location}</> : <></>}</span>
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default Post;
