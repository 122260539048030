import React, { useEffect } from 'react';
import { getPaginatedPosts } from '../functions/firebase';
import Feed from './feed';
import { useInfiniteQuery } from '@tanstack/react-query';

function AllPostsFeed(props) {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['posts', props.user?.uid],
    queryFn: async ({ pageParam = null }) => {
      const result = await getPaginatedPosts(props.user.uid, pageParam);
      return result;
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.hasMore) return undefined;
      return lastPage.posts[lastPage.posts.length - 1];
    },
    enabled: !!props.user?.uid,
    staleTime: 5 * 60 * 1000, // Data considered fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Cache kept for 30 minutes
  });

  // Handle scroll event
  const handleScroll = () => {
    if (!data?.pages[0]?.posts.length) return;

    const posts = document.querySelectorAll('.post');
    if (!posts.length) return;

    const fifthLastPost = posts[Math.max(posts.length - 5, 0)];
    if (!fifthLastPost) return;

    const rect = fifthLastPost.getBoundingClientRect();
    if (rect.top < window.innerHeight * 1.5) {
      fetchNextPage();
    }
  };

  // Add scroll listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [data?.pages]);

  // Flatten all posts from all pages
  const allPosts = data?.pages.flatMap(page => page.posts) ?? [];

  return (
    <>
      <Feed posts={allPosts} user={props.user} />
      {isFetching && <div className="text-center py-4">Loading more posts...</div>}
      {!hasNextPage && <div className="text-center py-4">No more posts to load</div>}
    </>
  );
}

export default AllPostsFeed;
