import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore, runTransaction, collection, where, setDoc, doc, getDoc, getDocs, addDoc, updateDoc, deleteDoc, serverTimestamp, query, orderBy, onSnapshot, increment, writeBatch, limit, startAfter } from "firebase/firestore";
import { getAuth, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithRedirect, GoogleAuthProvider, signInAnonymously } from "firebase/auth";
import Compressor from 'compressorjs';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBfk0WWfEIVgooSGQeaD0D-f1Tz-QlJzBs",
    authDomain: "human-60275.firebaseapp.com",
    projectId: "human-60275",
    storageBucket: "human-60275.appspot.com",
    messagingSenderId: "530566231862",
    appId: "1:530566231862:web:52de1a13990be0f4c76597",
    measurementId: "G-B283FVCH6Z"
  };
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const provider = new GoogleAuthProvider();
const auth = getAuth();

const signInWithGoogle = async () => {
    signInWithRedirect(auth, provider);
    // signInWithRedirect(auth, provider).then((result) => {
    //     const credential = GoogleAuthProvider.credentialFromResult(result);
    //     const token = credential.accessToken;
    //     const user = result.user;

    // }).catch((error) => {
    //     // Handle Errors here.
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     const email = error.customData.email;
    //     const credential = GoogleAuthProvider.credentialFromError(error);
    // });
}

const createNewUserAccount = async (email, password) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        // You can perform additional actions with the user object if needed
        return { successfulSignup: true, user: user };
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        return { successfulSignup: false, error: errorCode + ": " + errorMessage };
    }
}

const signInUser = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        // You can perform additional actions with the user object if needed
        return { successfulLogin: true, user: user };
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        return { successfulLogin: false, error: errorCode + ": " + errorMessage };
    }
}

const anonSignIn = async () => {
    signInAnonymously(auth)
        .then(() => {
            // Signed in..
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
        });
} 

const userSignOut = async () => {
    try {
        await signOut(auth);
        return true;
    } catch (error) {
        console.error("Error signing out: ", error);
        return false;
    }
}

const isNewUser = async (userID) => {
    const docRef = doc(db, "users", userID);
    const docSnap = await getDoc(docRef);
    
    if(docSnap.exists()){
        return false;
    } else {
        return true;
    }
}

const createNewUserWithHobbies = async (uid, name, hobbies, city, username) => {
    try {
        // Validate required username
        if (!username) {
            throw new Error("Username is required");
        }

        // Convert username to lowercase for consistency
        const lowercaseUsername = username.toLowerCase();

        // Create a batch write
        const batch = writeBatch(db);

        // Create a reference to the user document with the specified uid
        const userDocRef = doc(db, "users", uid);

        // Create a reference to the username document
        const usernameDocRef = doc(db, "usernames", lowercaseUsername);

        // Set the user document with the provided uid
        batch.set(userDocRef, {
            displayName: name,
            username: lowercaseUsername,  // Store lowercase username
            all_posts_count: 0,
            location: city,
            profile_pic_url: 'https://static.vecteezy.com/system/resources/thumbnails/020/911/737/small_2x/user-profile-icon-profile-avatar-user-icon-male-icon-face-icon-profile-icon-free-png.png',
            createdAt: serverTimestamp()
        });

        // Set the username document
        batch.set(usernameDocRef, {
            uid: uid
        });

        // Commit the batch write
        await batch.commit();

        console.log("User created with ID: ", uid);

        // Create a reference to the hobbies subcollection within the new user document
        const hobbiesCollectionRef = collection(db, "users", uid, "hobbies");

        // Add each hobby as a new document in the hobbies subcollection
        for (let i = 0; i < hobbies.length; i++) {
            let hobby = hobbies[i];
            await addDoc(hobbiesCollectionRef, {
                hobbyName: hobby,
                post_count: 0,
                cover_photo_url: 'https://firebasestorage.googleapis.com/v0/b/human-60275.appspot.com/o/util%2Fdefault-hobby-cover.png?alt=media&token=812390f8-a4e0-4a22-aa8e-150e6878c8af',
                color_index: i + 1,
                addedAt: serverTimestamp()
            });
        }

        console.log("Hobbies added for user: ", uid);
        return true;
    } catch (e) {
        console.error("Error creating user or adding hobbies: ", e);
        return false;
    }
}

const getProfileInfo = async (userId) => {
    try {
        // Create a reference to the user document
        const userDocRef = doc(db, "users", userId);
        
        // Get the user document
        const userDocSnap = await getDoc(userDocRef);
        
        if (!userDocSnap.exists()) {
            console.log('User not created or found')
            return null
        } else {
            // Extract user data
            const userData = userDocSnap.data();

            // Create a reference to the hobbies subcollection
            const hobbiesCollectionRef = collection(db, "users", userId, "hobbies");

            // Get all documents from the hobbies subcollection
            const hobbiesSnap = await getDocs(hobbiesCollectionRef);

            // Extract hobbies data
            const hobbies = hobbiesSnap.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            // Structure the profile info as a JSON object
            const profileInfo = {
                ...userData,
                hobbies: hobbies
            };

            return profileInfo;
        }
    } catch (e) {
        console.error("Error getting profile info: ", e);
        throw e; // Rethrow the error to be handled by the caller if necessary
    }
}

const updateProfileInfo = async (userId, updatedInfo) => {
    try {
        // Create a reference to the user document
        const userDocRef = doc(db, "users", userId);
        
        // Prepare the updated data
        const updatedData = {
            ...updatedInfo,
            updatedAt: serverTimestamp() // Adds a timestamp using Firebase's server time
        };

        // Update the user document with the new data
        await updateDoc(userDocRef, updatedData);

        console.log("Profile updated successfully for user ID: ", userId);
        return true;
    } catch (e) {
        console.error("Error updating profile info: ", e);
        return false;
    }
}

const updateHobbyInfo = async (userId, hobbyId, updatedInfo) => {
    try {
        // Create a reference to the specific hobby document within the user's hobbies collection
        const hobbyDocRef = doc(db, "users", userId, "hobbies", hobbyId);
        
        // Prepare the updated data
        const updatedData = {
            ...updatedInfo,
            updatedAt: serverTimestamp() // Adds a timestamp using Firebase's server time
        };

        // Update the hobby document with the new data
        await updateDoc(hobbyDocRef, updatedData);

        console.log("Hobby updated successfully for hobby ID: ", hobbyId);
        return true;
    } catch (e) {
        console.error("Error updating hobby info: ", e);
        return false;
    }
}

const createNewPost = async (userId, postInfo) => {
    try {
        console.log(postInfo);

        // Create references to the posts collection, hobby document, and user document
        const postsCollectionRef = collection(db, "users", userId, "posts");
        const hobbyDocRef = doc(db, "users", userId, "hobbies", postInfo.hobby.id);
        const userDocRef = doc(db, "users", userId);

        await runTransaction(db, async (transaction) => {
            // Get the current post_count for the hobby
            const hobbyDocSnapshot = await transaction.get(hobbyDocRef);
            if (!hobbyDocSnapshot.exists()) {
                throw new Error("Hobby document does not exist!");
            }
            const currentHobbyPostCount = hobbyDocSnapshot.data().post_count || 0;

            // Get the current all_posts_count for the user
            const userDocSnapshot = await transaction.get(userDocRef);
            if (!userDocSnapshot.exists()) {
                throw new Error("User document does not exist!");
            }
            const currentUserPostCount = userDocSnapshot.data().all_posts_count || 0;

            // Handle both single imageUrl and multiple images
            const imageData = postInfo.imageURL 
                ? { imageUrl: postInfo.imageURL, images: [postInfo.imageURL] }  // Legacy single image
                : { images: postInfo.images || [] };  // New multiple images format

            // Add a new post document to the posts collection
            const newPostRef = await addDoc(postsCollectionRef, {
                title: postInfo.title ?? null,
                content: postInfo.content ?? null,
                location: postInfo.location ?? null,
                hobby: postInfo.hobby ?? null,
                ...imageData,  // Spread both imageUrl and images array
                postDate: postInfo.postDate ?? serverTimestamp(),
                milestone: postInfo.milestone ?? null,
                privatePost: postInfo.privatePost ?? null,
                linked_project: postInfo.linked_project ?? null,
                createdAt: serverTimestamp()
            });

            console.log("Post created with ID: ", newPostRef.id);

            // Update the post_count for the specific hobby
            transaction.update(hobbyDocRef, {
                post_count: currentHobbyPostCount + 1
            });

            // Update the all_posts_count for the user
            transaction.update(userDocRef, {
                all_posts_count: currentUserPostCount + 1
            });
        });

        return true;
    } catch (e) {
        console.error("Error creating post or updating counts: ", e);
        return false;
    }
}

const getPostById = async (userId, postId) => {
    try {
        // Create a reference to the post document
        const postDocRef = doc(db, "users", userId, "posts", postId);

        // Get the post document
        const postDocSnap = await getDoc(postDocRef);

        if (postDocSnap.exists()) {
            // Extract post data
            const postData = postDocSnap.data();
            return postData;
        } else {
            return null;
        }
    } catch (e) {
        console.error("Error retrieving post: ", e);
        return null;
    }
}


const getAllPosts = async (userId) => {
    try {
        // Create a reference to the posts collection
        const postsCollectionRef = collection(db, "users", userId, "posts");

        // Create a query against the collection, ordered by createdAt in descending order
        const q = query(postsCollectionRef, orderBy("postDate", "desc"));

        // Get all documents that match the query
        const querySnapshot = await getDocs(q);

        // Extract data from the query snapshot
        const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        return posts;
    } catch (e) {
        console.error("Error retrieving posts: ", e);
        return [];
    }
}

const getPostsByHobby = async (userId, hobbyId) => {
    try {
        // Create a reference to the posts collection
        const postsCollectionRef = collection(db, "users", userId, "posts");

        // Create a query against the collection
        const q = query(postsCollectionRef, where("hobby.id", "==", hobbyId), orderBy("postDate", "desc"));

        // Get all documents that match the query
        const querySnapshot = await getDocs(q);

        // Extract data from the query snapshot
        const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        console.log("Posts retrieved: ", posts);
        return posts;
    } catch (e) {
        console.error("Error retrieving posts: ", e);
        return [];
    }
}

// Given a photo file, upload the photo to Firebase Storage
// The directory_location parameter specifies the folder in Firebase Storage to upload the photo
// Expected values for directory_location are: "profile_pictures" and "photos"
const uploadPhoto = (file, userID, directory_location) => {
    return new Promise((resolve, reject) => {
        // Compress the image file
        new Compressor(file, {
            quality: 0.8, // You can adjust the quality if needed
            maxWidth: 1080,
            success(compressedFile) {
                // Proceed with the upload using the compressed file
                const storage = getStorage(app);
                const storageRef = ref(storage, `users/${userID}/${directory_location}/${compressedFile.name}`);
                const uploadTask = uploadBytesResumable(storageRef, compressedFile);

                uploadTask.on('state_changed', 
                    (snapshot) => {
                        // Handle progress
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                    }, 
                    (error) => {
                        // Handle error
                        console.error("Error uploading file: ", error);
                        reject(error);
                    }, 
                    () => {
                        // Handle successful uploads
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            resolve(downloadURL);
                        }).catch((error) => {
                            reject(error);
                        });
                    }
                );
            },
            error(err) {
                console.error("Error compressing file: ", err);
                reject(err);
            },
        });
    });
}

const deletePost = async (userId, postId) => {
    try {
        // Create a reference to the post document
        const postDocRef = doc(db, "users", userId, "posts", postId);

        // Get the post data before deleting
        const postSnapshot = await getDoc(postDocRef);
        const postData = postSnapshot.data();

        // Delete the post document
        await deleteDoc(postDocRef);

        // Update the post count in the user's profile
        const userDocRef = doc(db, "users", userId);
        await updateDoc(userDocRef, {
            all_posts_count: increment(-1)
        });

        // Update the post count for the hobby
        if (postData && postData.hobby && postData.hobby.id) {
            const hobbyDocRef = doc(db, "users", userId, "hobbies", postData.hobby.id);
            await updateDoc(hobbyDocRef, {
                post_count: increment(-1)
            });
        }

        console.log("Post deleted successfully and counts updated for post ID: ", postId);
        return true;
    } catch (e) {
        console.error("Error deleting post and updating counts: ", e);
        return false;
    }
}

// TEMPORARY FUNCTION TO ADD postDate FIELD TO EXISTING POSTS

// const addPostDateToUserPosts = async (userId) => {
//     try {
//         // Create a reference to the posts collection for the specified user
//         const postsCollectionRef = collection(db, "users", userId, "posts");
        
//         // Get all posts for the user
//         const postsSnapshot = await getDocs(postsCollectionRef);

//         // Iterate over each post
//         for (const postDoc of postsSnapshot.docs) {
//             const postData = postDoc.data();
//             const createdAt = postData.createdAt;

//             // Check if postDate already exists to avoid redundant updates
//             if (!postData.postDate && createdAt) {
//                 // Create a reference to the specific post document
//                 const postDocRef = doc(db, "users", userId, "posts", postDoc.id);

//                 // Add postDate field with the value of createdAt
//                 await updateDoc(postDocRef, { postDate: createdAt });

//                 console.log(`Updated post ID: ${postDoc.id} for user ID: ${userId}`);
//             }
//         }

//         console.log(`All posts for user ID ${userId} have been processed.`);
//     } catch (error) {
//         console.error("Error updating posts: ", error);
//     }
// };

// // Function to process all users
// const processAllUsersPosts = async () => {
//     try {
//         // Get all users (Assuming you have a collection for users)
//         const usersCollectionRef = collection(db, "users");
//         const usersSnapshot = await getDocs(usersCollectionRef);

//         // Iterate over each user and update their posts
//         for (const userDoc of usersSnapshot.docs) {
//             const userId = userDoc.id;
//             await addPostDateToUserPosts(userId);
//         }

//         console.log("All users' posts have been updated.");
//     } catch (error) {
//         console.error("Error processing users' posts: ", error);
//     }
// };

const deleteHobby = async (userId, hobbyId) => {
    try {
        // Get all posts for this hobby first
        const postsCollectionRef = collection(db, "users", userId, "posts");
        const q = query(postsCollectionRef, where("hobby.id", "==", hobbyId));
        const postsSnapshot = await getDocs(q);
        const postCount = postsSnapshot.docs.length;

        // Delete all posts for this hobby
        const batch = writeBatch(db);
        postsSnapshot.docs.forEach((postDoc) => {
            batch.delete(doc(db, "users", userId, "posts", postDoc.id));
        });

        // Delete the hobby document
        batch.delete(doc(db, "users", userId, "hobbies", hobbyId));

        // Update user's total post count
        const userRef = doc(db, "users", userId);
        batch.update(userRef, {
            all_posts_count: increment(-postCount)
        });

        // Commit the batch
        await batch.commit();

        console.log(`Hobby ${hobbyId} and its ${postCount} posts deleted successfully`);
        return true;
    } catch (e) {
        console.error("Error deleting hobby and its posts: ", e);
        return false;
    }
};

const createNewProject = async (userId, projectInfo) => {
    try {
        // Create references to the projects collection and user document
        const projectsCollectionRef = collection(db, "users", userId, "projects");
        const hobbyDocRef = doc(db, "users", userId, "hobbies", projectInfo.hobby.id);

        await runTransaction(db, async (transaction) => {
            // Get the current project_count for the hobby (create if doesn't exist)
            const hobbyDocSnapshot = await transaction.get(hobbyDocRef);
            if (!hobbyDocSnapshot.exists()) {
                throw new Error("Hobby document does not exist!");
            }
            const currentHobbyProjectCount = hobbyDocSnapshot.data().project_count || 0;

            // Add a new project document to the projects collection
            const newProjectRef = await addDoc(projectsCollectionRef, {
                title: projectInfo.title ?? null,
                description: projectInfo.description ?? null,
                hobby: projectInfo.hobby ?? null,
                status: projectInfo.status ?? 'active',
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                imageUrl: projectInfo.imageUrl ?? 'https://firebasestorage.googleapis.com/v0/b/human-60275.appspot.com/o/util%2Fdefault-hobby-cover.png?alt=media&token=812390f8-a4e0-4a22-aa8e-150e6878c8af'
            });

            console.log("Project created with ID: ", newProjectRef.id);

            // Update the project_count for the specific hobby
            transaction.update(hobbyDocRef, {
                project_count: currentHobbyProjectCount + 1
            });
        });

        return true;
    } catch (e) {
        console.error("Error creating project: ", e);
        return false;
    }
}

const getProjects = async (userId, hobbyId = null) => {
    try {
        // Create a reference to the projects collection
        const projectsCollectionRef = collection(db, "users", userId, "projects");

        // Create a query against the collection
        let q;
        if (hobbyId) {
            // If hobbyId is provided, filter by hobby and order by creation date
            q = query(projectsCollectionRef, 
                where("hobby.id", "==", hobbyId),
                orderBy("createdAt", "desc")
            );
        } else {
            // If no hobbyId, just order by creation date
            q = query(projectsCollectionRef, orderBy("createdAt", "desc"));
        }

        // Get all documents that match the query
        const querySnapshot = await getDocs(q);

        // Extract data from the query snapshot
        const projects = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        return projects;
    } catch (e) {
        console.error("Error retrieving projects: ", e);
        return [];
    }
}

const updateProject = async (userId, projectId, updatedInfo) => {
    try {
        // Create a reference to the specific project document
        const projectDocRef = doc(db, "users", userId, "projects", projectId);
        
        // Prepare the updated data
        const updatedData = {
            ...updatedInfo,
            updatedAt: serverTimestamp()
        };

        // Update the project document with the new data
        await updateDoc(projectDocRef, updatedData);

        console.log("Project updated successfully for project ID: ", projectId);
        return true;
    } catch (e) {
        console.error("Error updating project info: ", e);
        return false;
    }
}

const getProjectById = async (userId, projectId) => {
    try {
        // Create a reference to the project document
        const projectDocRef = doc(db, "users", userId, "projects", projectId);

        // Get the project document
        const projectDocSnap = await getDoc(projectDocRef);

        if (projectDocSnap.exists()) {
            // Extract project data and include the ID
            return { id: projectDocSnap.id, ...projectDocSnap.data() };
        } else {
            return null;
        }
    } catch (e) {
        console.error("Error retrieving project: ", e);
        return null;
    }
}

const getPostsByProjectID = async (userId, projectId) => {
    try {
        // Create a reference to the posts collection
        const postsCollectionRef = collection(db, "users", userId, "posts");

        // Create a query against the collection
        const q = query(
            postsCollectionRef, 
            where("linked_project.projectId", "==", projectId),
            orderBy("postDate", "desc")
        );

        // Get all documents that match the query
        const querySnapshot = await getDocs(q);

        // Extract data from the query snapshot
        const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        console.log("Posts retrieved for project: ", posts);
        return posts;
    } catch (e) {
        console.error("Error retrieving posts for project: ", e);
        return [];
    }
}

const uploadProjectPhoto = async (file, userId, projectId) => {
    return uploadPhoto(file, userId, `project_photos/${projectId}`);
};

const deleteProject = async (userId, projectId) => {
    try {
        // Create a reference to the project document
        const projectDocRef = doc(db, "users", userId, "projects", projectId);

        // Get all posts linked to this project
        const postsCollectionRef = collection(db, "users", userId, "posts");
        const q = query(postsCollectionRef, where("linked_project.projectId", "==", projectId));
        const postsSnapshot = await getDocs(q);

        // Create a batch write
        const batch = writeBatch(db);

        // Update all linked posts to remove the project reference
        postsSnapshot.docs.forEach((postDoc) => {
            batch.update(doc(db, "users", userId, "posts", postDoc.id), {
                linked_project: null
            });
        });

        // Delete the project document
        batch.delete(projectDocRef);

        // Commit the batch
        await batch.commit();

        console.log(`Project ${projectId} deleted and ${postsSnapshot.docs.length} posts unlinked successfully`);
        return true;
    } catch (e) {
        console.error("Error deleting project: ", e);
        return false;
    }
};

const getPaginatedPosts = async (userId, lastPost = null, postLimit = 10) => {
    try {
        const postsCollectionRef = collection(db, "users", userId, "posts");
        
        // Create base query
        let q;
        if (lastPost) {
            q = query(postsCollectionRef, 
                orderBy("postDate", "desc"), 
                startAfter(lastPost.postDate),
                limit(postLimit)
            );
        } else {
            q = query(postsCollectionRef, 
                orderBy("postDate", "desc"), 
                limit(postLimit)
            );
        }

        const querySnapshot = await getDocs(q);
        const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Check if there are more posts to load
        const hasMore = posts.length === postLimit;

        return { posts, hasMore };
    } catch (e) {
        console.error("Error retrieving paginated posts: ", e);
        return { posts: [], hasMore: false };
    }
}

const checkHobbyHasProjects = async (userId, hobbyId) => {
    try {
        // Create a reference to the projects collection
        const projectsCollectionRef = collection(db, "users", userId, "projects");

        // Create a query to check for any projects with this hobbyId
        const q = query(
            projectsCollectionRef,
            where("hobby.id", "==", hobbyId),
            limit(1)  // We only need to know if at least one exists
        );

        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    } catch (e) {
        console.error("Error checking for projects: ", e);
        return false;
    }
}

const getAllUsers = async () => {
    try {
        const usersCollectionRef = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollectionRef);
        
        const users = usersSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        
        return users;
    } catch (error) {
        console.error("Error fetching users:", error);
        return [];
    }
}

export const updatePost = async (userId, postId, postData) => {
    try {
        const updateData = {
            updatedAt: serverTimestamp()
        };

        // Only add fields that are not null or undefined
        if (postData.content !== undefined) updateData.content = postData.content;
        if (postData.hobby !== undefined) updateData.hobby = postData.hobby;
        if (postData.title !== undefined) updateData.title = postData.title;
        if (postData.imageURL !== undefined) {
            updateData.imageUrl = postData.imageURL;
            updateData.images = [postData.imageURL];  // Maintain consistency with new format
        }
        if (postData.images !== undefined) {
            updateData.images = postData.images;
            updateData.imageUrl = postData.images[0] || null;  // Keep imageUrl as first image for backwards compatibility
        }
        if (postData.location !== undefined) updateData.location = postData.location;
        if (postData.milestone !== undefined) updateData.milestone = postData.milestone;
        if (postData.privatePost !== undefined) updateData.privatePost = postData.privatePost;
        if (postData.linked_project !== undefined) updateData.linked_project = postData.linked_project;

        // Handle postDate separately - if it's not provided, get the existing postDate from the document
        if (postData.postDate !== undefined) {
            updateData.postDate = postData.postDate;
        } else {
            // Get the current document to preserve the existing postDate
            const postDoc = await getDoc(doc(db, 'users', userId, 'posts', postId));
            if (postDoc.exists()) {
                updateData.postDate = postDoc.data().postDate;
            }
        }

        await updateDoc(doc(db, 'users', userId, 'posts', postId), updateData);
        return true;
    } catch (error) {
        console.error("Error updating post:", error);
        return false;
    }
};

const isAdminUser = async (userId) => {
    try {
        // Check if the user's ID exists as a document in the adminIds collection
        const adminDocRef = doc(db, "adminIds", userId);
        const adminDocSnap = await getDoc(adminDocRef);
        
        return adminDocSnap.exists();
    } catch (e) {
        console.error("Error checking admin status: ", e);
        return false;
    }
}

const checkUsernameExists = async (username) => {
    try {
        // Convert username to lowercase to ensure case-insensitive uniqueness
        const lowercaseUsername = username.toLowerCase();
        
        // Create a reference to the username document
        const usernameDocRef = doc(db, "usernames", lowercaseUsername);
        
        // Check if the document exists
        const usernameDoc = await getDoc(usernameDocRef);
        
        return usernameDoc.exists();
    } catch (e) {
        console.error("Error checking username: ", e);
        throw e;
    }
}

const getUserIdByUsername = async (username) => {
    try {
        // Convert username to lowercase to match storage format
        const lowercaseUsername = username.toLowerCase();
        
        // Create a reference to the username document
        const usernameDocRef = doc(db, "usernames", lowercaseUsername);
        
        // Get the document
        const usernameDoc = await getDoc(usernameDocRef);
        
        if (usernameDoc.exists()) {
            // Return the uid stored in the username document
            return usernameDoc.data().uid;
        } else {
            return null;
        }
    } catch (e) {
        console.error("Error looking up username: ", e);
        return null;
    }
}

export { 
    db, 
    auth, 
    signInWithGoogle, 
    anonSignIn, 
    userSignOut, 
    isNewUser, 
    createNewUserWithHobbies, 
    getProfileInfo,
    uploadPhoto,
    createNewPost,
    getAllPosts,
    getPostsByHobby,
    updateProfileInfo,
    createNewUserAccount,
    signInUser,
    updateHobbyInfo,
    getPostById,
    deletePost,
    deleteHobby,
    createNewProject,
    getProjects,
    updateProject,
    getProjectById,
    getPostsByProjectID,
    uploadProjectPhoto,
    deleteProject,
    getPaginatedPosts,
    checkHobbyHasProjects,
    getAllUsers,
    isAdminUser,
    checkUsernameExists,
    getUserIdByUsername
}