import React, { useState, useEffect } from 'react';
import { getAllUsers, auth, isAdminUser } from '../../functions/firebase';
import { Line } from 'react-chartjs-2';
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import AllUsers from '../AllUsers';
import '../../style/admin.css';
import { 
    getUserDetails, 
    deleteUser, 
    migrateUsersToUsername, 
    getSystemStats, 
    searchUsers 
} from '../../functions/firebase-admin';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function AdminHome() {
    const [users, setUsers] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('home');
    const [systemStats, setSystemStats] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showStats, setShowStats] = useState(false);

    useEffect(() => {
        checkAdminStatus();
    }, []);

    const checkAdminStatus = async () => {
        const user = auth.currentUser;
        if (user) {
            const adminStatus = await isAdminUser(user.uid);
            setIsAdmin(adminStatus);
            if (adminStatus) {
                fetchUsers();
            }
        }
        setLoading(false);
    };

    const fetchUsers = async () => {
        const usersData = await getAllUsers();
        setUsers(usersData);
        
        // Process data for the chart
        const usersByDate = processUsersForChart(usersData);
        setChartData({
            labels: usersByDate.map(item => item.date),
            datasets: [{
                label: 'Total Users',
                data: usersByDate.map(item => item.count),
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }]
        });
    };

    const processUsersForChart = (users) => {
        // Sort users by creation date
        const sortedUsers = users
            .filter(user => user.createdAt)
            .sort((a, b) => a.createdAt.toDate() - b.createdAt.toDate());

        if (sortedUsers.length === 0) return [];
        const firstDate = sortedUsers[0].createdAt.toDate();
        const lastDate = sortedUsers[sortedUsers.length - 1].createdAt.toDate();
        
        // Get the first Sunday by going back to the previous Sunday
        const firstSunday = new Date(firstDate);
        firstSunday.setDate(firstSunday.getDate() - firstSunday.getDay());
        
        // Calculate total number of weeks
        const totalWeeks = Math.ceil((lastDate - firstSunday) / (7 * 24 * 60 * 60 * 1000));
        
        // Initialize array with all weeks
        const usersByWeek = new Array(totalWeeks + 1).fill(0);
        
        // Count users per week
        sortedUsers.forEach(user => {
            const userDate = user.createdAt.toDate();
            const weekIndex = Math.floor((userDate - firstSunday) / (7 * 24 * 60 * 60 * 1000));
            // Increment this week and all future weeks
            for (let i = weekIndex; i < usersByWeek.length; i++) {
                usersByWeek[i]++;
            }
        });

        // Convert to chart format with Sunday dates
        const chartData = usersByWeek.map((count, index) => {
            const weekDate = new Date(firstSunday);
            weekDate.setDate(weekDate.getDate() + (index * 7));
            return {
                date: weekDate.toLocaleDateString('en-US', { 
                    month: 'short', 
                    day: 'numeric'
                }),
                count: count
            };
        });

        return chartData;
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'User Growth Per Week'
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Total Users'
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Week Date'
                }
            }
        }
    };

    const fetchSystemStats = async () => {
        const stats = await getSystemStats();
        setSystemStats(stats);
    };

    // Add new functions
    const handleSearch = async () => {
        if (searchTerm.trim()) {
            const results = await searchUsers(searchTerm);
            setSearchResults(results);
        }
    };

    const handleUserSelect = async (userId) => {
        const details = await getUserDetails(userId);
        setSelectedUser(details);
    };

    const handleDeleteUser = async (userId) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            await deleteUser(userId);
            fetchUsers(); // Refresh user list
            setSelectedUser(null);
        }
    };

    // const handleAddUserNames = async () => {
    //     if (window.confirm('Are you sure you want to add usernames to all users? This action cannot be undone.')) {
    //         try {
    //             await migrateUsersToUsername();
    //             alert('Successfully added usernames to users');
    //         } catch (error) {
    //             console.error('Error adding usernames:', error);
    //             alert('Failed to add usernames');
    //         }
    //     }
    // };

    if (loading ||!isAdmin) {
        return (
            <Container className="mt-4">
                <h1>404 - Page Not Found</h1>
                <p>Sorry, this page does not exist.</p>
            </Container>
        );
    }

    return (
        <Container className="admin-container mt-4">
            <Row className="mb-4 align-items-center">
                <Col>
                    <h1>Admin Dashboard</h1>
                </Col>
                <Col>
                    <Nav variant="tabs" className="admin-nav">
                        <Nav.Item>
                            <Nav.Link 
                                active={activeTab === 'home'} 
                                onClick={() => setActiveTab('home')}
                            >
                                Home
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link 
                                active={activeTab === 'users'} 
                                onClick={() => setActiveTab('users')}
                            >
                                Users
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link 
                                active={activeTab === 'search'} 
                                onClick={() => setActiveTab('search')}
                            >
                                Search Users
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link 
                                active={activeTab === 'system'} 
                                onClick={() => setActiveTab('system')}
                            >
                                System Actions
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>

            {activeTab === 'home' ? (
                <>
                    <Row className="mb-4">
                        <Col>
                            <button 
                                className="btn btn-primary mb-3"
                                onClick={() => {
                                    fetchSystemStats();
                                    setShowStats(true);
                                }}
                                disabled={showStats}
                            >
                                Get System Stats
                            </button>
                        </Col>
                    </Row>
                    {showStats && (
                        <Row className="mb-4">
                            <Col md={3}>
                                <Card className="admin-card">
                                    <Card.Body>
                                        <Card.Title>Total Users</Card.Title>
                                        <Card.Text className="admin-metric">
                                            {systemStats?.totalUsers || 'Loading...'}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card className="admin-card">
                                    <Card.Body>
                                        <Card.Title>Total Posts</Card.Title>
                                        <Card.Text className="admin-metric">
                                            {systemStats?.totalPosts || 'Loading...'}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card className="admin-card">
                                    <Card.Body>
                                        <Card.Title>Total Hobbies</Card.Title>
                                        <Card.Text className="admin-metric">
                                                {systemStats?.totalHobbies || 'Loading...'}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card className="admin-card">
                                    <Card.Body>
                                        <Card.Title>Total Projects</Card.Title>
                                        <Card.Text className="admin-metric">
                                            {systemStats?.totalProjects || 'Loading...'}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col>
                            <Card className="admin-card">
                                <Card.Body>
                                    <Card.Title>User Growth</Card.Title>
                                    {chartData && (
                                        <div style={{ height: '400px' }}>
                                            <Line data={chartData} options={chartOptions} />
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </>
            ) : activeTab === 'users' ? (
                <AllUsers />
            ) : activeTab === 'search' ? (
                <Row>
                    <Col>
                        <Card className="admin-card">
                            <Card.Body>
                                <Card.Title>User Search</Card.Title>
                                <div className="d-flex mb-3">
                                    <input
                                        type="text"
                                        className="form-control me-2"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search users..."
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSearch();
                                            }
                                        }}
                                    />
                                    <button 
                                        className="btn btn-primary"
                                        onClick={handleSearch}
                                    >
                                        Search
                                    </button>
                                </div>
                                {searchResults.length > 0 && (
                                    <div className="search-results">
                                        {searchResults.map(user => (
                                            <div 
                                                key={user.id} 
                                                className="p-2 border-bottom"
                                                onClick={() => handleUserSelect(user.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {user.displayName}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    {selectedUser && (
                        <Col>
                            <Card className="admin-card">
                                <Card.Body>
                                    <Card.Title>User Details</Card.Title>
                                    <div>
                                        <p><strong>Name:</strong> {selectedUser.displayName}</p>
                                        <p><strong>Email:</strong> {selectedUser.email}</p>
                                        <p><strong>Posts:</strong> {selectedUser.postsCount}</p>
                                        <p><strong>Hobbies:</strong> {selectedUser.hobbiesCount}</p>
                                        <div className="mt-3">
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => handleDeleteUser(selectedUser.id)}
                                            >
                                                Delete User
                                            </button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            ) : activeTab === 'system' ? (
                <Row>
                    <Col>
                        <Card className="admin-card">
                            <Card.Body>
                                <Card.Title>System Actions</Card.Title>
                                {/* <button 
                                    className="btn btn-primary"
                                    onClick={handleAddUserNames}
                                >
                                    Add User Names
                                </button> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ) : null}
        </Container>
    );
}

export default AdminHome;
