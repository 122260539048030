import React, { useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faXmark, faUser, faCirclePlus, faLightbulb, faLocationDot, faEllipsis, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { createNewUserWithHobbies, checkUsernameExists } from '../functions/firebase';

const styles = {
  bottomNav: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    maxWidth: '700px',
    margin: '0 auto',
    paddingBottom: '30px' // Added padding from bottom of screen
  },
  progressContainer: {
    padding: '0.5rem 1rem'
  },
  progressBar: {
    height: '4px',
    backgroundColor: '#e9ecef',
    borderRadius: '2px',
    display: 'flex',
    gap: '4px'
  },
  progressSegment: {
    flex: 1,
    borderRadius: '2px',
    transition: 'background-color 0.3s ease'
  },
  buttonContainer: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  nextButton: {
    paddingLeft: '32px',
    paddingRight: '32px'
  },
  deleteButton: {
    marginLeft: '10px',
    color: '#dc3545'
  },
  hobbyInput: {
    marginBottom: '12px'
  }
};

function NewUserPage(props) {
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [hobbies, setHobbies] = useState(['']);
  const [city, setCity] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [username, setUsername] = useState('');
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);

  const capitalizeFirstLetter = (str) => {
    if (str.length === 0) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleNameChange = (e) => {
    const capitalizedValue = capitalizeFirstLetter(e.target.value);
    setName(capitalizedValue);
  };

  const handleHobbyChange = (index, e) => {
    const newHobbies = [...hobbies];
    newHobbies[index] = e.target.value;
    setHobbies(newHobbies);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const addHobby = () => {
    if (!hobbies.includes('')) {
      setHobbies([...hobbies, '']);
    }
  };

  const deleteHobby = (index) => {
    const newHobbies = hobbies.filter((_, i) => i !== index);
    setHobbies(newHobbies);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value.toLowerCase().replace(/[^a-z]/g, '').slice(0, 19);
    setUsername(value);
    setErrorMessage('');
  };

  const nextPage = () => {
    if (page === 1 && name.trim() === '') {
      setErrorMessage('Please enter your name');
      return;
    }
    if (page === 2) {
      const nonEmptyHobbies = hobbies.filter(hobby => hobby.trim() !== '');
      if (nonEmptyHobbies.length === 0) {
        setErrorMessage('Please enter at least one hobby');
        return;
      }
    }
    setErrorMessage('');
    setPage(page + 1);
  };

  const prevPage = () => {
    setErrorMessage('');
    setPage(page - 1);
  };

  const createNewUser = async () => {
    if (city.trim() === '') {
      setErrorMessage('Please enter your city');
      return;
    }
    if (username.trim() === '') {
      setErrorMessage('Please enter a username');
      return;
    }
    if (username.length > 19) {
      setErrorMessage('Username must be 19 characters or less');
      return;
    }
    if (!/^[a-z]+$/.test(username)) {
      setErrorMessage('Username can only contain letters');
      return;
    }

    setIsCheckingUsername(true);
    try {
      const usernameExists = await checkUsernameExists(username);
      if (usernameExists) {
        setErrorMessage('This username is already taken');
        setIsCheckingUsername(false);
        return;
      }

      const nonEmptyHobbies = hobbies.filter(hobby => hobby.trim() !== '');
      setErrorMessage('');
      console.log('Creating new user with:', { name, hobbies: nonEmptyHobbies, city, username });
      var completeUserCreation = await createNewUserWithHobbies(props.user.uid, name, nonEmptyHobbies, city, username);
      if(completeUserCreation){
        props.userCompletedOnboarding();
      }
    } catch (error) {
      setErrorMessage('Error checking username availability');
      console.error(error);
    } finally {
      setIsCheckingUsername(false);
    }
  };

  return (
    <div className='new-user-page'>
      <Container>
        {page === 1 && (
          <>
            <Row>
              <Col>
                <h1>Welcome to Hobbiist</h1>
                <p>Let's get to know you and your hobbies</p>
                <p>What's your name?</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <input 
                  type="text" 
                  placeholder="Name" 
                  value={name} 
                  onChange={handleNameChange} 
                />
              </Col>
            </Row>
          </>
        )}

        {page === 2 && (
          <>
            <Row>
              <Col>
                <h1>What are your hobbies {name}?</h1>
                <p>This could be hobbies you're currently doing, or hobbies you're planning to do. You can add one or as many as you'd like</p>
              </Col>
            </Row>
            {hobbies.map((hobby, index) => (
              <Row key={index}>
                <Col xs={10}>
                  <input 
                    type="text" 
                    placeholder="Woodworking, Crocheting, etc." 
                    value={hobby} 
                    onChange={(e) => handleHobbyChange(index, e)} 
                    style={styles.hobbyInput}
                  />
                </Col>
                <Col xs={1}>
                  {hobbies.length > 1 && (
                    <Button 
                      variant="link"
                      onClick={() => deleteHobby(index)} 
                      style={styles.deleteButton}
                      disabled={hobbies.length === 1}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </Button>
                  )}
                </Col>
              </Row>
            ))}
            <Row>
              <Col>
                <Button 
                  variant="outline-primary"
                  onClick={addHobby} 
                  disabled={hobbies.includes('')}
                >
                  Add Another Hobby
                </Button>
              </Col>
            </Row>
          </>
        )}

        {page === 3 && (
          <>
            <Row>
              <Col>
                <h1>Almost done!</h1>
                <p>Where are you located?</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <input 
                  type="text" 
                  placeholder="City" 
                  value={city} 
                  onChange={handleCityChange} 
                />
              </Col>
            </Row>
          </>
        )}

        {page === 4 && (
          <>
            <Row>
              <Col>
                <h1>Final step!</h1>
                <p>Choose a username. This will be your public profile name, unique to you.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <input 
                  type="text" 
                  placeholder="Username" 
                  value={username} 
                  onChange={handleUsernameChange}
                />
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </Col>
        </Row>
      </Container>

      <div style={styles.bottomNav}>
        <div style={styles.progressContainer}>
          <div style={styles.progressBar}>
            <div style={{
              ...styles.progressSegment,
              backgroundColor: page >= 1 ? '#74b9ff' : '#e9ecef'
            }} />
            <div style={{
              ...styles.progressSegment,
              backgroundColor: page >= 2 ? '#74b9ff' : '#e9ecef'
            }} />
            <div style={{
              ...styles.progressSegment,
              backgroundColor: page >= 3 ? '#74b9ff' : '#e9ecef'
            }} />
            <div style={{
              ...styles.progressSegment,
              backgroundColor: page >= 4 ? '#74b9ff' : '#e9ecef'
            }} />
          </div>
        </div>

        <div style={styles.buttonContainer}>
          <div>
            {page > 1 && (
              <Button 
                variant="outline-secondary" 
                onClick={prevPage}
              >
                Back
              </Button>
            )}
          </div>
          <div>
            {page < 4 && (
              <Button 
                onClick={nextPage}
                className="primary-btn-fill"
                style={styles.nextButton}
              >
                Next
              </Button>
            )}
            {page === 4 && (
              <Button 
                onClick={createNewUser}
                className="primary-btn-fill"
                disabled={isCheckingUsername}
              >
                {isCheckingUsername ? 'Checking username...' : 'Create Profile'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewUserPage;
