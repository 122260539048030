import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col, Form, Button } from 'react-bootstrap';
import { getAllUsers, getAllPosts } from '../functions/firebase';

function AllUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userPosts, setUserPosts] = useState({});
  const [sortBy, setSortBy] = useState('recentPosts');
  const [sortOrder, setSortOrder] = useState('desc');

  useEffect(() => {
    const fetchUsersAndPosts = async () => {
      const usersList = await getAllUsers();
      setUsers(usersList);

      const postsData = {};
      for (const user of usersList) {
        const posts = await getAllPosts(user.id);
        if (posts.length > 0) {
          const sortedPosts = [...posts].sort((a, b) => {
            if (!a.createdAt || !b.createdAt) return 0;
            return b.createdAt.toDate() - a.createdAt.toDate();
          });
          
          const mostRecentPost = sortedPosts[0];
          postsData[user.id] = mostRecentPost;
        }
      }
      setUserPosts(postsData);
      setLoading(false);
    };

    fetchUsersAndPosts();
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const sortedUsers = [...users].sort((a, b) => {
    let comparison = 0;
    
    if (sortBy === 'recentPosts') {
      const aPost = userPosts[a.id];
      const bPost = userPosts[b.id];
      
      if (aPost && bPost) {
        comparison = bPost.createdAt.toDate() - aPost.createdAt.toDate();
      } else if (aPost) {
        comparison = -1;
      } else if (bPost) {
        comparison = 1;
      } else if (a.updatedAt && b.updatedAt) {
        comparison = b.updatedAt.toDate() - a.updatedAt.toDate();
      }
    } else { // sortBy === 'joinDate'
      if (a.createdAt && b.createdAt) {
        comparison = b.createdAt.toDate() - a.createdAt.toDate();
      }
    }
    
    return sortOrder === 'desc' ? comparison : -comparison;
  });

  return (
    <Container className="py-4">
      <div className="mb-4">
        <h1 className="mb-2">All Users ({users.length})</h1><br></br>
        <div className="d-flex gap-2 align-items-center mb-4">
          <Form.Select 
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            style={{ width: 'auto' }}
            size="sm"
          >
            <option value="recentPosts">Sort by Recent Posts</option>
            <option value="joinDate">Sort by Join Date</option>
          </Form.Select>
          <Button 
            variant="outline-secondary"
            size="sm"
            onClick={() => setSortOrder(current => current === 'desc' ? 'asc' : 'desc')}
          >
            {sortOrder === 'desc' ? '↓ Desc' : '↑ Asc'}
          </Button>
        </div>
      </div>
      
      <Row className="g-3">
        {sortedUsers.map(user => (
          <Col key={user.id} xs={12}>
            <Card>
              <Card.Body>
                <a 
                  href={`https://hobbi.ist/u/${user.id}/profile/feed`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="d-flex align-items-center">
                    <img 
                      src={user.profile_pic_url} 
                      alt={user.displayName} 
                      style={{ 
                        width: '50px', 
                        height: '50px', 
                        borderRadius: '50%',
                        objectFit: 'cover',
                        marginRight: '1rem'
                      }}
                    />
                    <div>
                      <Card.Title className="mb-0">
                        {user.displayName || 'Anonymous User'}
                      </Card.Title>
                      <Card.Text className="text-muted mb-0">
                        {user.all_posts_count || 0} posts
                        {(user.location || user.id) && ' • '}
                        {user.location}
                        {user.id && ` • ${user.id.substring(0, 6)}`}
                      </Card.Text>
                      {user.createdAt && (
                        <Card.Text className="text-muted mb-0" style={{ fontSize: '0.9em' }}>
                          {userPosts[user.id] ? (
                            <>
                              <a
                                href={`https://hobbi.ist/u/${user.id}/post/${userPosts[user.id].id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                                style={{ textDecoration: 'underline' }}
                              >
                                Last post: {formatDate(userPosts[user.id].createdAt)}
                              </a>
                              {' • '}
                            </>
                          ) : null}
                          Joined {formatDate(user.createdAt)}
                        </Card.Text>
                      )}
                    </div>
                  </div>
                </a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default AllUsers; 