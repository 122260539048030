import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NewPostHeader({ 
    page, 
    postType, 
    selectedHobbyIndex, 
    onBack, 
    onChangePage, 
    onTogglePostType, 
    onSubmit,
    isEditMode 
}) {
    return (
        <div className='page-header-nav'>
            <Container fluid>
                <Row>
                    <Col xs="2">
                        <div className='circ-btn subtext-gray' onClick={onBack}>
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </div>
                    </Col>
                    <Col className='title' xs="8">
                        {isEditMode ? (
                            <div>Edit Post</div>
                        ) : postType === 'multipost' ? (
                            <div>Multiple Posts</div>
                        ) : (
                            <div className="toggle-container">
                                <div 
                                    className={`toggle-option ${postType === 'post' ? 'active' : ''}`}
                                    onClick={() => postType !== 'post' && onTogglePostType()}
                                >
                                    Post
                                </div>
                                <div 
                                    className={`toggle-option ${postType === 'project' ? 'active' : ''}`}
                                    onClick={() => postType !== 'project' && onTogglePostType()}
                                >
                                    Project
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col xs="2">
                        {postType !== 'multipost' && (
                            <div className="primary-text-color">
                                <p onClick={() => {
                                    // if (selectedHobbyIndex !== null) {
                                        if (page === 'one') {
                                            onChangePage('two');
                                        } else {
                                            onSubmit();
                                        }
                                    // }
                                }}>
                                    {page === 'one' ? 'Next' : 'Share'}
                                </p>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default NewPostHeader; 