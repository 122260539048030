import React, { useState, useEffect } from 'react';

import './App.css';

import mixpanel from "mixpanel-browser";

import { Row, Col, Container, Button } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faXmark, faUser, faCirclePlus, faLightbulb, faLocationDot, faEllipsis, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { isNewUser } from './functions/firebase';
import { ToastContainer } from 'react-toastify';

import { getProfileInfo } from './functions/firebase';
import NewUserPage from './pages/newUser';
import { ProfileProvider } from './assets/data/providerContext';
import { Routes, Route, useNavigate, Outlet, Link } from 'react-router-dom';

function CoreApp(props) {

  library.add(fas, faXmark, faUser, faCirclePlus, faLightbulb, faLocationDot, faEllipsis, faChevronLeft);

  const[activeNav, setActiveNav] = useState('loading');
  const [user, setUser] = useState(props.user);
  const [profileInfo, setProfileInfo] = useState(null);

  const navigate = useNavigate();

  // Check if user is new or known
  // If new, set activeNav to 'new-user'
  // If known, set activeNav to 'core-app'
  useEffect(() => {
    const checkKnownUser = async () => {
      const newUser = await isNewUser(user.uid)
      if(newUser) {
        setActiveNav('new-user');
      } else {
        setActiveNav('core-app');
        const baseUrl = `/u/${user.uid}`;
        if (window.location.pathname === baseUrl) {
          navigate(`${baseUrl}/profile/about`);
        }
      }
    }

    if(user == null){
      navigate('/');
    } else {
      checkKnownUser();
    }
  }, []);
  // Get profile info on user login
  useEffect(() => {
    const getProfile = async () => {
      const profile = await getProfileInfo(props.user.uid);
      if (profile?.displayName) {
        mixpanel.people.set({ '$name': profile.displayName });
      }
      setProfileInfo(profile);
    }

    if (props.user != null ) { getProfile() }
  }, [props.user]);

  // Refresh profile info whenever called
  const refreshProfileInfo = async () => {
    const profile = await getProfileInfo(props.user.uid);
    setProfileInfo(profile);
  }

  // Function to be called when user completes onboarding
  const userCompletedOnboarding = async () => {
    await refreshProfileInfo();
    setActiveNav('core-app');
    navigate(`/u/${user.uid}/profile/about`,{});
  }

  const checkIfUserLoggedIn = () => {
    if(user.accessToken){
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="App">
      {activeNav === 'loading' && (
        <div></div>
      )}
      {activeNav === 'new-user' && (
        <NewUserPage user={user} userCompletedOnboarding={userCompletedOnboarding} />
      )}
      {activeNav === 'core-app' && (
        <ProfileProvider value={{ profileInfo, refreshProfileInfo }}>
          <Outlet />
          { checkIfUserLoggedIn() && (
            <div className='nav'>
              <Container fluid>
                <Row>
                  <Col xs>
                    <Link to="profile/feed">
                      <div className='nav-btn active'>
                        <div className='icon'>
                          <FontAwesomeIcon icon="fa-solid fa-user" />
                        </div>
                        <span></span>
                      </div>
                    </Link>
                  </Col>
                  
                  <Col xs>
                    <Link to="new">
                      <div className='nav-btn'>
                        <div className='icon'>
                          <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                        </div>
                        <span></span>
                      </div>
                    </Link>
                  </Col>

                  {/* <Col xs>
                    <div className='nav-btn'>
                      <div className='icon'>
                        <FontAwesomeIcon icon="fa-solid fa-lightbulb" />
                      </div>
                      <span></span>
                    </div>
                  </Col> */}
                </Row>
              </Container>
            </div>
          )}
        </ProfileProvider>
      )}
      <ToastContainer />
    </div>
  );
}

export default CoreApp;
