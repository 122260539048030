import { db } from './firebase';
import { 
    collection, 
    doc, 
    getDoc, 
    getDocs, 
    updateDoc, 
    deleteDoc, 
    query, 
    where, 
    writeBatch,
    serverTimestamp
} from "firebase/firestore";

// Get all users with their basic profile info
const getAllUsers = async () => {
    try {
        const usersCollectionRef = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollectionRef);
        
        const users = usersSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        
        return users;
    } catch (error) {
        console.error("Error fetching users:", error);
        return [];
    }
}

// Get detailed user info including posts and hobbies count
const getUserDetails = async (userId) => {
    try {
        // Get user profile
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);
        
        if (!userDoc.exists()) {
            return null;
        }

        // Get user's hobbies
        const hobbiesRef = collection(db, "users", userId, "hobbies");
        const hobbiesSnapshot = await getDocs(hobbiesRef);
        
        // Get user's posts
        const postsRef = collection(db, "users", userId, "posts");
        const postsSnapshot = await getDocs(postsRef);

        return {
            id: userId,
            ...userDoc.data(),
            hobbiesCount: hobbiesSnapshot.size,
            postsCount: postsSnapshot.size,
        };
    } catch (error) {
        console.error("Error fetching user details:", error);
        return null;
    }
}

// Delete a user and all their associated data
const deleteUser = async (userId) => {
    try {
        const batch = writeBatch(db);

        // Delete all posts
        const postsRef = collection(db, "users", userId, "posts");
        const postsSnapshot = await getDocs(postsRef);
        postsSnapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
        });

        // Delete all hobbies
        const hobbiesRef = collection(db, "users", userId, "hobbies");
        const hobbiesSnapshot = await getDocs(hobbiesRef);
        hobbiesSnapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
        });

        // Delete all projects
        const projectsRef = collection(db, "users", userId, "projects");
        const projectsSnapshot = await getDocs(projectsRef);
        projectsSnapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
        });

        // Delete user document
        const userRef = doc(db, "users", userId);
        batch.delete(userRef);

        // Commit the batch
        await batch.commit();
        return true;
    } catch (error) {
        console.error("Error deleting user:", error);
        return false;
    }
}

// Suspend/Unsuspend a user
const updateUserStatus = async (userId, isSuspended) => {
    try {
        const userRef = doc(db, "users", userId);
        await updateDoc(userRef, {
            isSuspended: isSuspended,
            statusUpdatedAt: serverTimestamp()
        });
        return true;
    } catch (error) {
        console.error("Error updating user status:", error);
        return false;
    }
}

// Get system statistics
const getSystemStats = async () => {
    try {
        const usersRef = collection(db, "users");
        const usersSnapshot = await getDocs(usersRef);
        
        let totalPosts = 0;
        let totalHobbies = 0;
        let totalProjects = 0;
        
        // Calculate totals
        for (const userDoc of usersSnapshot.docs) {
            const userId = userDoc.id;
            
            const postsSnapshot = await getDocs(collection(db, "users", userId, "posts"));
            totalPosts += postsSnapshot.size;
            
            const hobbiesSnapshot = await getDocs(collection(db, "users", userId, "hobbies"));
            totalHobbies += hobbiesSnapshot.size;
            
            const projectsSnapshot = await getDocs(collection(db, "users", userId, "projects"));
            totalProjects += projectsSnapshot.size;
        }

        return {
            totalUsers: usersSnapshot.size,
            totalPosts,
            totalHobbies,
            totalProjects,
            lastUpdated: new Date().toISOString()
        };
    } catch (error) {
        console.error("Error getting system stats:", error);
        return null;
    }
}

// Search users by display name
const searchUsers = async (searchTerm) => {
    try {
        const usersRef = collection(db, "users");
        const usersSnapshot = await getDocs(usersRef);
        
        // Filter users whose display name includes the search term (case insensitive)
        const filteredUsers = usersSnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(user => 
                user.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
            );

        return filteredUsers;
    } catch (error) {
        console.error("Error searching users:", error);
        return [];
    }
}

// Migrate users to include username field and create username collection
const migrateUsersToUsername = async () => {
    try {
        const batch = writeBatch(db);
        const usersRef = collection(db, "users");
        const usersSnapshot = await getDocs(usersRef);
        
        for (const userDoc of usersSnapshot.docs) {
            const userData = userDoc.data();
            const displayName = userData.displayName;
            
            if (!displayName) {
                console.warn(`User ${userDoc.id} has no displayName, skipping...`);
                continue;
            }

            // Transform displayName: remove spaces and convert to lowercase
            const username = displayName.replace(/\s+/g, '').toLowerCase();

            // Update user document with username field
            const userRef = doc(db, "users", userDoc.id);
            batch.update(userRef, {
                username: username
            });

            // Create username document
            const usernameRef = doc(db, "usernames", username);
            batch.set(usernameRef, {
                uid: userDoc.id
            });
        }

        // Commit all changes
        await batch.commit();
        console.log("Successfully migrated users to include usernames");
        return true;
    } catch (error) {
        console.error("Error migrating users to username:", error);
        return false;
    }
}

export {
    getAllUsers,
    getUserDetails,
    deleteUser,
    updateUserStatus,
    getSystemStats,
    searchUsers,
    migrateUsersToUsername
}; 